$theme: (
	primary: (
		base: $brand-primary,
		light: lighten($brand-primary, $color-lighten),
		dark: darken($brand-primary, $color-darken),
		opac: transparentize($brand-primary, $color-opac),
		sat: saturate($brand-primary, $color-sat),
		desat: desaturate($brand-primary, $color-desat)
	),
	secondary: (
		base: $brand-secondary,
		light: lighten($brand-secondary, $color-lighten),
		dark: darken($brand-secondary, $color-darken),
		opac: transparentize($brand-secondary, $color-opac),
		sat: saturate($brand-secondary, $color-sat),
		desat: desaturate($brand-secondary, $color-desat)
	),
	highlight: (
		base: $brand-highlight,
		light: lighten($brand-highlight, $color-lighten),
		dark: darken($brand-highlight, $color-darken), 
		opac: transparentize($brand-highlight, $color-opac),
		sat: saturate($brand-highlight, $color-sat),
		desat: desaturate($brand-highlight, $color-desat)
	),
	accent: (
		base: $brand-accent,
		light: lighten($brand-accent, $color-lighten),
		dark: darken($brand-accent, $color-darken),
		opac: transparentize($brand-accent, $color-opac),
		sat: saturate($brand-accent, $color-sat),
		desat: desaturate($brand-accent, $color-desat)
	),
	review: (
		base: $color-review,
		light: lighten($color-review, $color-lighten),
		dark: darken($color-review, $color-darken),
		opac: transparentize($color-review, $color-opac),
		sat: saturate($color-review, $color-sat),
		desat: desaturate($color-review, $color-desat)
	),
	success: (
		base: $color-success,
		light: lighten($color-success, $color-lighten),
		dark: darken($color-success, $color-darken),
		opac: transparentize($color-success, $color-opac),
		sat: saturate($color-success, $color-sat),
		desat: desaturate($color-success, $color-desat)
	),
	danger: (
		base: $color-danger,
		light: lighten($color-danger, $color-lighten),
		dark: darken($color-danger, $color-darken),
		opac: transparentize($color-danger, $color-opac),
		sat: saturate($color-danger, $color-sat),
		desat: desaturate($color-danger, $color-desat)
	),
	link: (
		base: $color-link,
		light: lighten($color-link, $color-lighten),
		dark: darken($color-link, $color-darken),
		opac: transparentize($color-link, $color-opac),
		sat: saturate($color-link, $color-sat),
		desat: desaturate($color-link, $color-desat)
	)	
);

$brand-primary-dark: theme(primary, dark);
$brand-primary-light: theme(primary, light);

$brand-secondary-dark: theme(secondary, dark);
$brand-secondary-light: theme(secondary, light);

$brand-highlight-dark: theme(highlight, dark);
$brand-highlight-light: theme(highlight, light);

$brand-accent-dark: theme(accent, dark);
$brand-accent-light: theme(accent, light);

$color-review-dark: theme(review, dark);
$color-review-light: theme(review, light);

$color-danger-dark: theme(danger, dark);
$color-danger-light: theme(danger, light);

$color-success-dark: theme(success, dark);
$color-success-light: theme(success, light);


// SITE COLORS


$body-bg:				#f5efe8;
$color-text:			#3b260c; //darken($brand-secondary, 36%);

$color-hover:			lighten($color-link, $color-lighten);

$color-phone:			$brand-primary;
 
$nav_bp_down: "md";
$nav_bp_up: "lg";

$nav-title: $brand-secondary-dark;

$nav-color-link: $white;
$nav-bg-link: $brand-secondary-dark;

$nav-color-hover: $white;
$nav-bg-hover: $brand-primary;

$nav-link-active: $brand-secondary-dark;

$dd-bg: $body-bg;

$dd-link-color: $brand-secondary-dark; //$nav-color-link;
$dd-bg-link: transparent; //$nav-bg-link;

$dd-link-hover: $brand-primary-dark;
$dd-bg-hover: transparent;

$dd-link-active: $dd-link-hover;
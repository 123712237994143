.directions {

    &_block {
        padding: 30px 15px;
        background-color: $body-bg;
        text-align: center;
        .row { justify-content: center; }
        @include media-breakpoint-up(lg) {
            .row {
                align-items: center;
                justify-content: space-between;
            }
            .wrap {
                width: auto;
            }
        }
    }

    &_title {
        font-size: 105%;
        font-weight: $body-weight-bold;
        letter-spacing: -0.04em;
        strong { font-size: 110%; }
    }

    &_address {
        @include media-breakpoint-up(sm) {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            address { margin: 0 15px 0 0; }
        }
        @include media-breakpoint-only(lg) {
            display: unset;
            address { margin-bottom: 5px; }
        }
    }

    &-button {
        @include btn(10px 15px, 90px, inline-block);
		@include btn-solid(#4180df, $blue, white);
		@include font-size(16);
        font-weight: $body-weight-bold;
        @include media-breakpoint-up(lg) {
            @include btn(5px 10px, 90px, inline-block);
            @include font-size(14);
        }
    }

    &_hours {
        p { line-height: 1.2; }
        @include media-breakpoint-only(xs) {
            &.two { margin-top: 0 !important; }
        }
        @include media-breakpoint-between(sm,md) {
            width: auto;
        } 
    }

}
// FROM BOOTSTRAP FUNCTIONS
@mixin _assert-ascending($map, $map-name) {
	$prev-key: null;
	$prev-num: null;

	@each $key,
	$num in $map {
		@if $prev-num==null or unit($num)=="%"or unit($prev-num)=="%" {
			// Do nothing
		}

		@else if not comparable($prev-num, $num) {
			@warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
		}

		@else if $prev-num>=$num {
			@warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
		}

		$prev-key: $key;
		$prev-num: $num;
	}
}

@mixin _assert-starts-at-zero($map, $map-name: "$grid-breakpoints") {
	@if length($map)>0 {
		$values: map-values($map);
		$first-value: nth($values, 1);

		@if $first-value !=0 {
			@warn "First breakpoint in #{$map-name} must start at 0, but starts at #{$first-value}.";
		}
	}
}


// FROM BOOTSTRAP MIXINS/BREAKPOINTS

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	}
	@else {
		@content;
	}
}


@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);
	@if $max {
		@media (max-width: $max) {
			@content;
		}
	}

	@else {
		@content;
	}
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($lower, $breakpoints);
	$max: breakpoint-max($upper, $breakpoints);
	@if $min !=null and $max !=null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	}
	@else if $max==null {
		@include media-breakpoint-up($lower, $breakpoints) {
			@content;
		}
	}
	@else if $min==null {
		@include media-breakpoint-down($upper, $breakpoints) {
			@content;
		}
	}
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$max: breakpoint-max($name, $breakpoints);
	@if $min !=null and $max !=null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	}
	@else if $max==null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	}
	@else if $min==null {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}


// FROM BOOTSTRAP MIXINS/GRID
@mixin make-container($gutter: $grid-gutter-width) {
	width: 100%;
	padding-right: $gutter / 2;
	padding-left: $gutter / 2;
	margin-right: auto;
	margin-left: auto;
}

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
	@each $breakpoint,
	$container-max-width in $max-widths {
		@include media-breakpoint-up($breakpoint, $breakpoints) {
			max-width: $container-max-width;
		}
	}
}

@mixin make-row($gutter: $grid-gutter-width) {
	display: flex;
	flex-wrap: wrap;
	margin-right: -$gutter / 2;
	margin-left: -$gutter / 2;
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
	position: relative;
	width: 100%;
	//padding: ($gutter / 4) ($gutter / 2); 
	/* &:not(:first-child) {
		padding-top: $gutter / 2;
	}
	&:not(:last-child) {
		padding-bottom: $gutter / 2;
	} */
	padding-right: $gutter / 2;
	padding-left: $gutter / 2;
}

@mixin make-col($size, $columns: $grid-columns) {
	flex: 0 0 percentage($size / $columns);
	max-width: percentage($size / $columns);
}

@mixin make-col-auto() {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%; // Reset earlier grid tiers
}

@mixin make-col-offset($size, $columns: $grid-columns) {
	$num: $size / $columns;
	margin-left: if($num==0, 0, percentage($num));
}

@mixin row-cols($count) {
	&>* {
		flex: 0 0 100% / $count;
		max-width: 100% / $count;
	}
}
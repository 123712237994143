// FONT-SIZES PX to REM conversion
@mixin font-size($size, $base: 16) {
	font-size: $size * 1px;
	font-size: ($size / $base) * 1rem;
}

@mixin antialias {
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin heading($size, $weight, $sizeMD) {
	@include font-size($size);
	font-weight: $weight;
	@include media-breakpoint-up(md) {
		@include font-size($sizeMD);
	}
}

// LISTS
@mixin limbo {
	li {
		margin-bottom: 0 !important;
	}
}

@mixin list_spaced($margin: 10px) {
	li:not(:last-child) {
		margin-bottom: $margin;
	}
}

@mixin list_unstyled {
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;
	list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

@mixin list_inline {
	@include list_unstyled;

	> li {
		display: inline-block;
		&:not(:last-child) { margin-right: 15px; }
	}
}

// FORM PLACEHOLDERS
@mixin input-placeholder {
	&.placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
} // input { @include-placeholder { color: $grey } }


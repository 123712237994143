.map {

    &_block {
        background-color: white;
        .embed_responsive {
            height: 360px;
        }
        @include media-breakpoint-up(md) {
            .embed_responsive {
                height: 420px;
            }
        }
    }

}
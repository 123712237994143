.services {
	
	&_block {
		padding: 45px 15px;		
	}

	&_title {
		text-align: center;
		margin-bottom: 30px;
	}

	&_items {

		.wrap {
			padding: 15px;
		}

		@include media-breakpoint-up(md) {
			.wrap {
				@include make-col(1,2);
			}
		}

		@include media-breakpoint-up(lg) {
			.wrap {
				@include make-col(1,3);
			}
		}

	}

	&_more {
		margin-top: 30px;
		text-align: center;
	}

}


.service {

	&_item {
		height: 100%;
		border: 1px solid #dbc09d;
		background-color: $body-bg;
		h3 {
			margin: 30px 0 0;
			padding: 0 30px;
		}
		p {
			padding: 15px 30px 30px;
			margin-bottom: 0;
		}
		@include media-breakpoint-down(sm) {

			picture {
				position: relative;
				width: 100%;
				height: 180px;
				overflow: hidden;
				img {
					width: 100%;
					height: auto;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			
			&-1 picture img {
				top: 15%;
				transform: translateY(-15%);
			}

		}
	}

	&-button {
		@include btn(15px 20px, 90px, inline-block);
		@include btn-ghost($body-bg, $brand-secondary, $white);
		@include font-size(19);
		font-weight: 800;
		letter-spacing: -0.04em;
    }

}
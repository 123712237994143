
.appointment {

    &_block {
        padding: 60px 15px;
        text-align: center;
        border-top: 1px solid #dbc09d;
        border-bottom: 1px solid #dbc09d;;
        background-color: $body-bg;
    }

	&-button {
		@include btn(15px 20px, 90px, inline-block);
		@include btn-ghost(tranparent, $brand-primary, $white);
		@include font-size(19);
		font-weight: 800;
		letter-spacing: -0.04em;
    }

}
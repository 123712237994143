
.info {

	&_block {
		padding: 30px 15px;
		background-color: #352410;
		color: white;
	}

	&-copyright {
		@include list-unstyled;
		@include media-breakpoint-down(sm) {
			@include list-spaced;
		}
		@include media-breakpoint-up(md) {
			@include list-inline;
			text-align: center;
		}
	}

}